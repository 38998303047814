<script>
import Swal from 'sweetalert2'

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);

        return {
        doesAddOpen,
        doesEditOpen,
        doesDeleteOpen,
        };
    },
    page: {
    title: "Directions",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Directions",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Directions",
          active: true,
        },
      ],
      errors: [],
      users: [],
      direction: {},
      directions: [],
      user_id: "",
      name: "",
      type: "",
      designation: "",
      description: "",
      email: "",
      phone: "",
      sup_entity_id: "",
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      if (localStorage.getItem("ENTITY") == "DGEFC") {
        fetch(Helper.route('entities'), Helper.requestOptions())
        .then(response => {
            Helper.tokenChecker(response.status)
            return response.json()
        })
        .then(data => {
          console.log(data.data);
          this.directions = data.data
        }) 
      }
    },
    editItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('_method', "PUT")
      formData.append('user_id', this.user_id)
      if (this.sup_entity_id != "") {
        formData.append('sup_entity_id', this.sup_entity_id) 
      }
      formData.append('name', this.name)
      formData.append('phone', this.phone)
      formData.append('email', this.email)
      formData.append('designation', this.designation)
      formData.append('description', this.description)
      formData.append('type', this.type)
      fetch(Helper.route('entities/'+this.direction.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(data);
        console.log(this.status);
        this.loading = false
        if (this.status == 200) {
          this.doesEditOpen = false
          this.directions = this.directions.map(i => i.id !== this.direction.id ? i : data.data)
          Swal.fire("Direction modifier !", "La Direction a été modifiée avec succès.", "success");

          if (data.data.type == "DGEFC") {
            Helper.tokenChecker(401)
          }
        } else {
            console.log(Helper.getError(data.data, "user_id"));
            this.errors.category_id = Helper.getError(data.data, "category_id")
            this.errors.user_id = Helper.getError(data.data, "user_id")
            this.errors.name = Helper.getError(data.data, "name")
            this.errors.phone = Helper.getError(data.data, "phone")
            this.errors.email = Helper.getError(data.data, "email")
            this.errors.designation = Helper.getError(data.data, "designation")
            this.errors.description = Helper.getError(data.data, "description")
            this.errors.sup_entity_id = Helper.getError(data.data, "sup_entity_id")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    loadUsers() {
      fetch(Helper.route('users?no_if_ctaf_direction=true'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        this.users = data.data
      })
    },
    initItem(direction, action="edit") {
        this.direction = direction
        this.initData(direction)
        if (action == "delete") {
        this.doesDeleteOpen = true
        } else {
        this.doesEditOpen = true
        }
    },
    initData(item = null) {
      this.user_id = item != null ? item.user_id : ""
      this.name = item != null ? item.name : ""
      this.email = item != null ? item.email : ""
      this.phone = item != null ? item.phone : ""
      this.designation = item != null ? item.designation : ""
      this.description = item != null ? item.description : ""
      this.type = item != null ? item.type : ""
      this.sup_entity_id = item != null ? item.sup_entity_id : ""
    }
  },
  mounted() {
    this.loadList()
    this.loadUsers()
  },
  watch: {
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.direction = null
        this.initData(null)
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des Directions</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>Directions</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Direction</th>
                    <th>Sup. Direction</th>
                    <th>Email</th>
                    <th>Responsable</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(direction, i) in directions" :key="direction.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <router-link :to="'/entity/directions/'+direction.id">{{ direction.name }}</router-link>
                    </td>
                    <td>
                      <template v-if="direction.sup_entity != null">
                        <router-link :to="'/entity/directions/'+direction.sup_entity.id">{{ direction.sup_entity.name }}</router-link>
                      </template>
                    </td>
                    <td>{{ direction.email }}</td>
                    <td>{{ direction.user.lastname +" "+direction.user.firstname }}</td>
                    <td class="hstack">
                        <b-button class="btn-sm" @click="initItem(direction)" variant="success">
                            <i
                            class="bx bx-edit font-size-16 align-middle mx-1"
                            ></i>
                        </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    


    <!-- EDIT MODAL -->
    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        centered
        size="lg"
        title="Modifier le Direction"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItem()">
            <div class="row">
            <div class="col-md-6 d-none mb-3">
                <select required v-model="type" class="form-control">
                    <option value="">Selectionnez la direction</option>
                    <option value="DGEFC">DGEFC</option>
                    <option value="DRAF">DRAF</option>
                    <option value="DSIME">DSIME</option>
                    <option value="SAFN">SAFN</option>
                    <option value="SRGP">SRGP</option>
                    <option value="SFC">SFC</option>
                    <option value="IF">IF</option>
                    <option value="CTAF">CTAF</option>
                </select>
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Direction supérieure</label>
                <select v-model="sup_entity_id" class="form-control">
                    <option value=""></option>
                    <template v-for="entity in directions" :key="entity.id">
                      <option v-if="(entity.type != direction.type) && direction != null" :value="entity.id">{{ entity.name }}</option>
                    </template>
                </select>
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Selectionnez le responsable</label>
                <select required v-model="user_id" class="form-control">
                    <option v-for="user in users" :key="user.id" :value="user.id">{{ user.lastname+" "+user.firstname }}</option>
                </select>
                <span class="text-danger mt-2" v-if="errors.user_id">{{ errors.user_id }}</span>
            </div>
            <div class="col-md-6 mb-3">
                <input type="text" required placeholder="Nom de la Direction" v-model="name" class="form-control">
                <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
            </div>
            <div class="col-md-6 mb-3">
                <input type="email" required placeholder="Email de la Direction" v-model="email" class="form-control">
                <span class="text-danger mt-2" v-if="errors.email">{{ errors.email }}</span>
            </div>
            <div class="col-md-6 mb-3">
                <input type="tel" required placeholder="Téléphone de la Direction" v-model="phone" class="form-control">
                <span class="text-danger mt-2" v-if="errors.phone">{{ errors.phone }}</span>
            </div>
            <div class="col-md-12 mb-3">
                <input type="text" placeholder="Désignation de la Direction" v-model="designation" class="form-control">
                <span class="text-danger mt-2" v-if="errors.designation">{{ errors.designation }}</span>
            </div>
            <div>
                <textarea v-model="description" required placeholder="Description de la Direction" class="form-control" rows="4"></textarea>
                <span class="text-danger mt-2" v-if="errors.description">{{ errors.description }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Supprimer le Direction"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer le Direction <b>{{ direction?.name }}</b>
        </p>
        <p class="text-danger">Attention ! Supprimer un Direction implique la suppression de ces projets.</p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>


  </Layout>
</template>
